import Environment from 'src/config/Environment';

const environment = Environment.TEST;

let debugConfig = {
    devMode: {
        enabled: false,
        config: {},
    },
    environment,
};

if (environment === Environment.LOCALHOST || environment === Environment.DEV) {
    debugConfig = {
        ...debugConfig,
        devMode: {
            enabled: true,
            config: {
                consoleLogLevel: 'INFO',
                disableSentry: true,
                logHttpToConsole: {
                    enabled: true,
                    verbose: false,
                },
            },
        },
    }
}

export default debugConfig

export function devModeEnabled() {
    return debugConfig.devMode.enabled;
}

export function devModeConfig() {
    return debugConfig.devMode.config;
}

const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef
if (isDebuggingInChrome) {
    window.LOG_LEVEL = devModeConfig().consoleLogLevel;
}


